import { createSlice } from '@reduxjs/toolkit';
import { IDashboard } from '../types/DashboardInfo';

const initialState: { data: IDashboard; isFirst: boolean } = {
  data: {
    videoControl: null,
    activityControl: null,
    doorsControl: null,
    accessControl: null,
    steamControl: null,
  },
  isFirst: true,
};

const dashboardSlice = createSlice({
  name: 'dashboardState',
  initialState,
  reducers: {
    setDashboardState: (state, { payload }): void => {
      state.data = payload;
    },
    setFirstRequest: (state, { payload }): void => {
      state.isFirst = payload;
    },
  },
});

export const { actions } = dashboardSlice;

export default dashboardSlice.reducer;
