import React, { Dispatch, useEffect } from 'react';
import { IDashboard } from '../types/DashboardInfo';
import { SocketModules } from '../../../../shared/types/types';
import useSocketConnection from '../../../../shared/hooks/useSocketConnection';
import { useAppSelector } from '../../../../app/providers/StoreProvider/hooks/hooks';

const useDashboardData = (
  isMain: boolean
): {
  data: IDashboard | null;
  loading: boolean;
  setData: Dispatch<IDashboard | null>;
} => {
  const isFirst = useAppSelector((state) => state.dashboardState.isFirst);
  const [dashboardData, setDashboardData] = React.useState<IDashboard | null>(null);

  const onmessageHandler = (event: MessageEvent<any>): void => {
    const data = JSON.parse(event.data)?.dashboard;

    setDashboardData((prev) => ({ ...prev, ...data }));
  };
  
  const { sendMessage } = useSocketConnection({
    onmessage: onmessageHandler,
  });

  useEffect(() => {
    if (isMain || !isFirst) {
      sendMessage({ module: SocketModules.DASHBOARD });
    }
  }, []);

  return { data: dashboardData, loading: false, setData: setDashboardData };
};
export default useDashboardData;
