import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from '../../../../environments/env';
import createPath from '../../../../shared/helpers/createPath';
import getLoginData from '../../../../shared/helpers/getLoginData';
import IGetReportDataParams from '../../model/interface/IGetReportDataParams';
import IReportData from '../../model/interface/IReportData';
import IReportsInfo from '../../model/interface/IReportsInfo';
import TReportType from '../../model/types/TReportType';

const api = env.REACT_APP_API_URL;

export const reportsApi = createApi({
  reducerPath: 'api/reports',
  baseQuery: fetchBaseQuery({
    baseUrl: createPath(api, 'reports'),
    prepareHeaders: (headers) => {
      const { token } = getLoginData();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getReportsConfig: build.query<IReportsInfo, unknown>({
      query: () => ({
        url: '/info',
        method: 'GET',
      }),
    }),
    getReportData: build.query<
      { reports: IReportData },
      {
        tab: TReportType;
        params: IGetReportDataParams;
      }
    >({
      query: ({ tab, params }) => ({
        url: `/${tab}/`,
        method: 'GET',
        params,
      }),
    }),
    getAccessReportsData: build.query<
      { reports: IReportData },
      {
        params: IGetReportDataParams;
      }
    >({
      query: ({ params }) => ({
        url: `/access`,
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: IReportData) => {
        return {
          reports: response,
        };
      },
    }),
  }),
});

export const { useGetReportDataQuery, useGetReportsConfigQuery, useGetAccessReportsDataQuery } =
  reportsApi;
