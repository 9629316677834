import clsx from 'clsx';
import styles from './Loader.module.scss';

interface LoaderProps {
  className?: string;
}

export const Loader = ({ className }: LoaderProps) => {
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.ellipsis}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
