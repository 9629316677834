import { forwardRef, createElement } from 'react';
import type { DetailedHTMLProps, PropsWithChildren, HTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './Typography.module.scss';
import { ITypographyProps } from '../../types/types';

// eslint-disable-next-line react/display-name
export const Typography = forwardRef<
  HTMLElement,
  PropsWithChildren<ITypographyProps> & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
>(({ children, component = 'span', type = 'p', className: currentClassName, ...rest }, ref) => {
  const className = clsx(styles.root, currentClassName, {
    [styles[`root_${type}`]]: type,
  });

  return createElement(component, { className, ref, ...rest }, children);
});

export default Typography;
